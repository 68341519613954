import React from "react";
import aboutOne from "../../components/App/assets/images/about1.jpg";
import aboutTwo from "../../components/App/assets/images/niyant.jpeg";

const calculateAge = (birthday) => {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const About = () => {
  return (
    <div id="about" className="about-area two pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src={aboutOne} alt="About" />
              <img
                src={aboutTwo}
                alt="About"
                style={{ filter: "grayscale(1)" }}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content">
              <div className="section-title two">
                <span className="sub-title">ABOUT ME</span>
                <h2>
                  Hey, I'm <span>Niyant Shah</span>.<br /> I am
                  <span>Full Stack Engineer</span>
                  <br /> Frontend, Backend, AWS Services, Linux Service
                </h2>
                <p>
                  I am a full-stack engineer and a keen problem solver who is
                  passionate about everything that involves programming and
                  technology. I have been crafting software for a decade now
                  solving complex business problems, in startups and enterprise
                  companies alike.
                </p>
                <p>
                  Developed multiple web, PWA and mobile applications working
                  with automated testing and deployment using AWS and sonar
                  cloud. I believe that real value is when I see my work/product
                  bring some benefit to the end-users and society.
                </p>
              </div>

              <div className="row">
                <div className="col-sm-6 col-lg-6">
                  <div className="left">
                    <ul>
                      <li>
                        <span>Age:</span>
                        {calculateAge("1992-02-26")} Years
                      </li>
                      <li>
                        <span>Residence:</span>
                        Gujarat
                      </li>
                      <li>
                        <span>Address:</span>
                        <a href="#">Ahmedabad, Gujarat, India</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6">
                  <div className="right">
                    <ul>
                      <li>
                        <span>Phone:</span>
                        <a href="tel:+919429628792">+919429628792</a>
                      </li>
                      <li>
                        <span>Email:</span>
                        <a href="mailto:niyantshah02@gmail.com">
                          niyantshah02@gmail.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
