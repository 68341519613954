import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../../utils/baseUrl";
import { useForm } from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const alertContent = () => {
  MySwal.fire({
    title: "Congratulations!",
    text: "Your message was successfully send and will back to you soon",
    icon: "success",
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

// Form initial state
const INITIAL_STATE = {
  name: "",
  email: "",
  number: "",
  subject: "",
  text: "",
};

const ContactForm = () => {
  const [contact, setContact] = useState(INITIAL_STATE);
  const { register, handleSubmit, errors } = useForm();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async (e) => {
    // e.preventDefault();
    try {
      const url = `${baseUrl}/api/contact`;
      const { name, email, number, subject, text } = contact;
      const payload = { name, email, number, subject, text };
      await axios.post(url, payload);
      console.log(url);
      setContact(INITIAL_STATE);
      alertContent();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="contact" className="contact-area two pb-70 border-top-1 pt-4">
      <div className="container">
        <div className="section-title two">
          <span className="sub-title">GET IN TOUCH</span>
          {/* <h2>Drop A Line Here</h2> */}
          <p>Let's talk about your project.</p>
        </div>

        <div className="row justify-content-center align-items-center">
          {/* <div className="col-md-7 col-lg-7">
            <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      value={contact.name}
                      onChange={handleChange}
                      ref={register({ required: true })}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.name && "Name is required."}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={contact.email}
                      onChange={handleChange}
                      ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.email && "Email is required."}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      value={contact.subject}
                      onChange={handleChange}
                      ref={register({ required: true })}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.subject && "Subject is required."}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="number"
                      className="form-control"
                      placeholder="Phone"
                      value={contact.number}
                      onChange={handleChange}
                      ref={register({ required: true })}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.number && "Number is required."}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      name="text"
                      className="form-control"
                      cols="30"
                      rows="6"
                      placeholder="Write message"
                      value={contact.text}
                      onChange={handleChange}
                      ref={register({ required: true })}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.text && "Text body is required."}
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn common-btn">
                Send Message <span></span>
              </button>
            </form>
          </div> */}

          <div className="col-md-12 col-lg-12">
            <div className="contact-content text-center">
              <div className="top">
                <ul>
                  <li>
                    <span>Phone:</span>
                    <a href="tel:+919429628792">+919429628792</a>
                  </li>
                  <li>
                    <span>Email:</span>
                    <a href="mailto:niyantshah02@gmail.com">
                      niyantshah02@gmail.com
                    </a>
                  </li>
                  <li>
                    <span>Address:</span>
                      Ahmedabad, Gujarat, India
                  </li>
                </ul>
              </div>

              <div className="bottom">
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/niyantshah02/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.behance.net/niyantshah"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="bx bxl-behance"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/niyant02"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="bx bxl-github"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
